import React, { useContext, useEffect } from 'react';
import { Nav, Collapse } from 'react-bootstrap';
import { RiShieldFlashFill } from 'react-icons/ri';
import { IoMdArrowDropdown, IoMdArrowDropup, IoIosArrowForward } from 'react-icons/io';
import { Link, useParams } from 'react-router-dom';
import ThemeContext from '../../ThemeContext';
import MenuContext from '../../MenuContext';

const SecurityDropdown = () => {
    const { username, domain } = useParams();
    const { theme } = useContext(ThemeContext);
    const { openDropdown, setOpenDropdown, activePath } = useContext(MenuContext);

    useEffect(() => {
        if (activePath.includes('security')) {
            setOpenDropdown('security');
        }
    }, [activePath, setOpenDropdown]);

    const handleToggle = () => {
        setOpenDropdown(openDropdown === 'security' ? '' : 'security');
    };

    const isActive = (path) => activePath.includes(path);

    return (
        <>
            <Nav.Link
                href="#"
                className={`d-flex align-items-center justify-content-between ${theme}`}
                onClick={handleToggle}
                aria-controls="security-collapse-text"
                aria-expanded={openDropdown === 'security'}
                style={{ cursor: 'pointer' }}
            >
                <div className="d-flex align-items-center">
                    <RiShieldFlashFill style={{ marginRight: '10px', fontSize: '22px', marginBottom: '5px', color: 'red' }} />
                    <span className={theme === 'dark' ? 'text-white' : 'text-dark'}>Security</span>
                </div>
                {openDropdown === 'security' ? <IoMdArrowDropup style={{color: 'red'}}/> : <IoMdArrowDropdown style={{color: 'red'}}/>}
            </Nav.Link>
            <Collapse in={openDropdown === 'security'}>
                <div id="security-collapse-text">
                    <Nav className="flex-column ml-3">
                        <Nav.Link as={Link} to={`/${username}/${domain}/events`} className={`d-flex align-items-center ${theme}`}>
                            <IoIosArrowForward style={{ marginRight: '10px', fontSize: '15px', marginBottom: '5px', color: 'red' }} />
                            <span className={theme === 'dark' ? 'text-white' : 'text-dark'}>
                                Events {isActive('events') && <span className="red-dot"></span>}
                            </span>
                        </Nav.Link>
                        <Nav.Link as={Link} to={`/${username}/${domain}/waf`} className={`d-flex align-items-center ${theme}`}>
                            <IoIosArrowForward style={{ marginRight: '10px', fontSize: '15px', marginBottom: '5px', color: 'red' }} />
                            <span className={theme === 'dark' ? 'text-white' : 'text-dark'}>
                                WAF {isActive('waf') && <span className="red-dot"></span>}
                            </span>
                        </Nav.Link>
                        {/* <Nav.Link as={Link} to={`/${username}/${domain}/bots`} className={`d-flex align-items-center ${theme}`}>
                            <IoIosArrowForward style={{ marginRight: '10px', fontSize: '15px', marginBottom: '5px', color: 'red' }} />
                            <span className={theme === 'dark' ? 'text-white' : 'text-dark'}>
                                Bots {isActive('bots') && <span className="red-dot"></span>}
                            </span>
                        </Nav.Link>
                        <Nav.Link as={Link} to={`/${username}/${domain}/ddos`} className={`d-flex align-items-center ${theme}`}>
                            <IoIosArrowForward style={{ marginRight: '10px', fontSize: '15px', marginBottom: '5px', color: 'red' }} />
                            <span className={theme === 'dark' ? 'text-white' : 'text-dark'}>
                                DDoS {isActive('ddos') && <span className="red-dot"></span>}
                            </span>
                        </Nav.Link>
                        <Nav.Link as={Link} to={`/${username}/${domain}/settings`} className={`d-flex align-items-center ${theme}`}>
                            <IoIosArrowForward style={{ marginRight: '10px', fontSize: '15px', marginBottom: '5px', color: 'red' }} />
                            <span className={theme === 'dark' ? 'text-white' : 'text-dark'}>
                                Settings {isActive('settings') && <span className="red-dot"></span>}
                            </span>
                        </Nav.Link> */}
                    </Nav>
                </div>
            </Collapse>
        </>
    );
};

export default SecurityDropdown;
