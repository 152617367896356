import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import { Container, Row, Col, Card, Dropdown, DropdownButton, Button } from 'react-bootstrap';
import { IoSettingsSharp } from "react-icons/io5";
import './Dashboard.css'; 
import './Login.css';
import './themes.css'; // Импортируем файл тем
import MenuNavigation from './Menu.js';
import { useTranslation } from 'react-i18next';
import ThemeContext from './ThemeContext';

const Settings = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate(); // Use useNavigate for navigation
    const token = localStorage.getItem('token');
    const username = localStorage.getItem('username');
    const { theme, setTheme } = useContext(ThemeContext);

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        localStorage.setItem('language', lng); // Сохранение выбранного языка в localStorage
    };

    const changeTheme = (newTheme) => {
        setTheme(newTheme);
    };

    const clearLocalStorageAndLogout = () => {
        localStorage.clear();
        navigate('/login');
    };

    return (
        <div className={`settings-container ${theme}`}>
            <Container fluid className="dashboard-container">
                <Row className="flex-grow-1">
                    <Col md={2} className={`border vh-100 ${theme}`}>
                        <MenuNavigation />
                    </Col>
                    <Col md={10} className={`border vh-100 ${theme}`}>
                        <Container className='left-align-container'>
                            <h2 className={theme} style={{ display: 'flex', alignItems: 'center' }}>
                                <IoSettingsSharp style={{ marginRight: '10px', fontSize: '22px', marginBottom: '5px', color: 'red' }} />
                                {t('Settings')} <span className={theme} style={{ fontSize: '18px', color: 'gray', marginLeft: '10px', marginTop: 'px'}}>hotwall@{username}'s account</span>
                            </h2>
                            <h6 className={theme}>{t('General settings hotwall account')}</h6>
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                <Card className={`card ${theme}`} style={{ border: '1px solid #444', padding: '10px', width: '900px', maxWidth: '900px', margin: '10px auto', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'stretch' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', paddingLeft: '20px', flex: 1 }}>
                                        <Card.Title className={`card-title ${theme}`} style={{ fontSize: '16px', marginBottom: '5px', textAlign: 'center', marginTop: '30px' }}>{t('Appearance')}</Card.Title>
                                        <Card.Text className={`card-text ${theme}`} style={{ fontSize: '13px', marginBottom: '35px', textAlign: 'left' }}>
                                            {t('Select how you’d like the HotWall Dashboard to appear on this device. Choose from light or dark themes, or opt to sync with your operating system’s settings.')}
                                        </Card.Text>
                                    </div>
                                    <div className={`divider ${theme}`} style={{ width: '1px', margin: '0 20px' }}></div>
                                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', paddingLeft: '20px' }}>
                                        <DropdownButton id="dropdown-basic-button" title={t('Theme')} variant='outline-danger' style={{ marginRight: '30px' }}>
                                            <Dropdown.Item onClick={() => changeTheme('dark')}>{t('Dark')}</Dropdown.Item>
                                            <Dropdown.Item onClick={() => changeTheme('light')}>{t('Light')}</Dropdown.Item>
                                        </DropdownButton>
                                    </div>
                                </Card>
                                <Card className={`card ${theme}`} style={{ border: '1px solid #444', padding: '10px', width: '900px', maxWidth: '900px', margin: '10px auto', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'stretch' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', paddingLeft: '20px', flex: 1 }}>
                                        <Card.Title className={`card-title ${theme}`} style={{ fontSize: '16px', marginBottom: '5px', textAlign: 'center', marginTop: '30px' }}>{t('Language Preference')}</Card.Title>
                                        <Card.Text className={`card-text ${theme}`} style={{ fontSize: '13px', marginBottom: '35px', textAlign: 'left' }}>
                                            {t('My preference for the language shown in the dashboard is:')}
                                        </Card.Text>
                                    </div>
                                    <div className={`divider ${theme}`} style={{ width: '1px', margin: '0 20px' }}></div>
                                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', paddingLeft: '20px' }}>
                                        <DropdownButton id="dropdown-basic-button" title={t('Language')} variant='outline-danger' style={{ marginRight: '30px' }}>
                                            <Dropdown.Item onClick={() => changeLanguage('en')}>English</Dropdown.Item>
                                            <Dropdown.Item onClick={() => changeLanguage('ru')}>Русский</Dropdown.Item>
                                        </DropdownButton>
                                    </div>
                                </Card>
                                <Button variant="outline-danger" onClick={clearLocalStorageAndLogout} style={{ margin: '20px auto' }}>
                                    {t('Logout')}
                                </Button>
                            </div>
                        </Container>
                    </Col>  
                </Row>
            </Container>
        </div>
    );
};

export default Settings;
