import React, { useEffect, useContext } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import './App.css';
import HotWallNavbar from './NavBar';
import ImageWithText from './ImageWithText';
import Login from './Login';
import Dashboard from './Dashboard';
import ProtectedRoute from './ProtectedRoute';
import Websites from './Websites';
import Profile from './Profile';
import Discover from './Discover';
import DomainRegitration from './DomainRegitration';
import SecurityCenter from './SecurityCenter';
import Settings from './Settings';
import DomainPage from './DomainPage';
import TrafficPage from './TrafficPage';
import EventsPage from './EventsPage';
import ThemeContext, { ThemeProvider } from './ThemeContext';
import { MenuProvider } from './MenuContext';
import './themes.css';
import WafPage from './WafPage';
import TariffsWidget from './pages/main/TariffWidget';

function App() {
  return (
    <Router>
      <ThemeProvider>
        <MenuProvider>
          <ThemeHandler>
            <div className="App">
              <Routes>
                <Route path="/" element={
                  <>
                    <HotWallNavbar />
                    <ImageWithText />
                    <TariffsWidget  />
                    <h1></h1>
                    <h1></h1>
                  </>
                } />
                <Route path="/login" element={<Login />} />
                <Route path="/dashboard" element={<ProtectedRoute element={<Dashboard />} />} />
                <Route path="/websites" element={<ProtectedRoute element={<Websites />} />} />
                <Route path="/profile" element={<ProtectedRoute element={<Profile />} />} />
                <Route path="/discover" element={<ProtectedRoute element={<Discover />} />} />
                <Route path="/registration-domains" element={<ProtectedRoute element={<DomainRegitration />} />} />
                <Route path="/security-center" element={<ProtectedRoute element={<SecurityCenter />} />} />
                <Route path="/settings" element={<ProtectedRoute element={<Settings />} />} />
                <Route path="/:username/:domain/overview" element={<ProtectedRoute element={<DomainPage />} />} />
                <Route path="/:username/:domain/traffic" element={<ProtectedRoute element={<TrafficPage />} />} />
                <Route path="/:username/:domain/events" element={<ProtectedRoute element={<EventsPage />} />} />
                <Route path="/:username/:domain/waf" element={<ProtectedRoute element={<WafPage />} />} />
              </Routes>
            </div>
          </ThemeHandler>
        </MenuProvider>
      </ThemeProvider>
    </Router>
  );
}

const ThemeHandler = ({ children }) => {
  const location = useLocation();
  const { theme, setTheme } = useContext(ThemeContext);

  useEffect(() => {
    if (location.pathname === '/' || location.pathname === '/login') {
      document.body.className = 'light';
    } else {
      document.body.className = theme;
    }
  }, [location.pathname, theme]);

  return <>{children}</>;
}

export default App;
