import React, { useContext, useEffect } from 'react';
import { Nav } from 'react-bootstrap';
import { FaFire } from 'react-icons/fa6';
import { IoReturnDownBack } from 'react-icons/io5';
import { GrDomain } from 'react-icons/gr';
import { BsArrowLeft } from 'react-icons/bs';
import { HiViewGridAdd } from 'react-icons/hi';
import { Link, useParams, useLocation } from 'react-router-dom';
import ThemeContext from './ThemeContext';
import MenuContext from './MenuContext';
import AnalyticsDropdown from './pages/domain/AnalyticsDropdown';
import SecurityDropdown from './pages/domain/SecurityDropdown';
import CachingDropdown from './pages/domain/CachingDropdown';
import './themes.css'; // Импортируем themes.css для классов темы

const MenuNavigation = () => {
    const { username, domain } = useParams();
    const { theme } = useContext(ThemeContext);
    const { activePath, setActivePath } = useContext(MenuContext);
    const location = useLocation();

    const linkStyle = theme === 'dark' ? { color: 'white' } : { color: 'inherit' };

    useEffect(() => {
        setActivePath(location.pathname);
    }, [location.pathname, setActivePath]);

    return (
        <Nav className={`flex-column ${theme}`}>
            <Nav.Item className={`d-flex position-flex-right ${theme}`} style={{ fontSize: '20px', margin: '15px', marginBottom: '50px' }}>
                <Link to="/websites" style={{ textDecoration: 'none', ...linkStyle }}>
                    <FaFire style={{ marginRight: '10px', fontSize: '24px', marginBottom: '5px', color: 'red' }} />
                    HotWall
                    <IoReturnDownBack style={{ marginLeft: '10px', fontSize: '24px', marginBottom: '5px', color: 'red', textAlign: 'right' }} />
                </Link>
            </Nav.Item>
            <Nav.Link as={Link} to={`/${username}/${domain}/overview`} className={`d-flex align-items-center ${theme}`} style={linkStyle}>
                <HiViewGridAdd style={{ marginRight: '10px', fontSize: '22px', marginBottom: '5px', color: 'red' }} />
                <span className={`menu-text ${theme}`}>Overview {activePath.includes('overview') && <span className="red-dot"></span>}</span>
            </Nav.Link>
            <AnalyticsDropdown />
            <SecurityDropdown />
            {/* <CachingDropdown /> */}
            {/* <Nav.Link as={Link} to={`/${username}/${domain}/rules`} className={`d-flex align-items-center ${theme}`} style={linkStyle}>
                <GrDomain style={{ marginRight: '10px', fontSize: '22px', marginBottom: '5px', color: 'red' }} />
                <span className={`menu-text ${theme}`}>Rules {activePath.includes('rules') && <span className="red-dot"></span>}</span>
            </Nav.Link> */}
            <hr className={`menu-divider ${theme}`} />
            <Nav.Link as={Link} to="/dashboard" className={`d-flex align-items-center ${theme}`} style={linkStyle}>
                <BsArrowLeft style={{ marginRight: '10px', fontSize: '22px', marginBottom: '5px', color: 'red' }} />
                <span className={`menu-text ${theme}`}>Back</span>
            </Nav.Link>
        </Nav>
    );
};

export default MenuNavigation;
