import React, { useContext, useEffect } from 'react';
import { Nav, Collapse } from 'react-bootstrap';
import { GrAnalytics } from 'react-icons/gr';
import { IoMdArrowDropdown, IoMdArrowDropup, IoIosArrowForward } from 'react-icons/io';
import { Link, useParams } from 'react-router-dom';
import ThemeContext from '../../ThemeContext';
import MenuContext from '../../MenuContext';

const AnalyticsDropdown = () => {
    const { username, domain } = useParams();
    const { theme } = useContext(ThemeContext);
    const { openDropdown, setOpenDropdown, activePath } = useContext(MenuContext);

    useEffect(() => {
        if (activePath.includes('analytics')) {
            setOpenDropdown('analytics');
        }
    }, [activePath, setOpenDropdown]);

    const handleToggle = () => {
        setOpenDropdown(openDropdown === 'analytics' ? '' : 'analytics');
    };

    const isActive = (path) => activePath.includes(path);

    return (
        <>
            <Nav.Link
                href="#"
                className={`d-flex align-items-center justify-content-between ${theme}`}
                onClick={handleToggle}
                aria-controls="analytics-collapse-text"
                aria-expanded={openDropdown === 'analytics'}
                style={{ cursor: 'pointer' }}
            >
                <div className="d-flex align-items-center">
                    <GrAnalytics style={{ marginRight: '10px', fontSize: '22px', marginBottom: '5px', color: 'red' }} />
                    <span className={theme === 'dark' ? 'text-white' : 'text-dark'}>Analytics & Logs</span>
                </div>
                {openDropdown === 'analytics' ? <IoMdArrowDropup style={{color: 'red'}}/> : <IoMdArrowDropdown style={{color: 'red'}}/>}
            </Nav.Link>
            <Collapse in={openDropdown === 'analytics'}>
                <div id="analytics-collapse-text">
                    <Nav className="flex-column ml-3">
                        <Nav.Link as={Link} to={`/${username}/${domain}/traffic`} className={`d-flex align-items-center ${theme}`}>
                            <IoIosArrowForward style={{ marginRight: '10px', fontSize: '15px', marginBottom: '5px', color: 'red' }} />
                            <span className={theme === 'dark' ? 'text-white' : 'text-dark'}>
                                Traffic {isActive('traffic') && <span className="red-dot"></span>}
                            </span>
                        </Nav.Link>
                        {/* <Nav.Link as={Link} to={`/${username}/${domain}/security`} className={`d-flex align-items-center ${theme}`}>
                            <IoIosArrowForward style={{ marginRight: '10px', fontSize: '15px', marginBottom: '5px', color: 'red' }} />
                            <span className={theme === 'dark' ? 'text-white' : 'text-dark'}>
                                Security {isActive('security') && <span className="red-dot"></span>}
                            </span>
                        </Nav.Link>
                        <Nav.Link as={Link} to={`/${username}/${domain}/performance`} className={`d-flex align-items-center ${theme}`}>
                            <IoIosArrowForward style={{ marginRight: '10px', fontSize: '15px', marginBottom: '5px', color: 'red' }} />
                            <span className={theme === 'dark' ? 'text-white' : 'text-dark'}>
                                Performance {isActive('performance') && <span className="red-dot"></span>}
                            </span>
                        </Nav.Link>
                        <Nav.Link as={Link} to={`/${username}/${domain}/dns`} className={`d-flex align-items-center ${theme}`}>
                            <IoIosArrowForward style={{ marginRight: '10px', fontSize: '15px', marginBottom: '5px', color: 'red' }} />
                            <span className={theme === 'dark' ? 'text-white' : 'text-dark'}>
                                DNS {isActive('dns') && <span className="red-dot"></span>}
                            </span>
                        </Nav.Link>
                        <Nav.Link as={Link} to={`/${username}/${domain}/workers`} className={`d-flex align-items-center ${theme}`}>
                            <IoIosArrowForward style={{ marginRight: '10px', fontSize: '15px', marginBottom: '5px', color: 'red' }} />
                            <span className={theme === 'dark' ? 'text-white' : 'text-dark'}>
                                Workers {isActive('workers') && <span className="red-dot"></span>}
                            </span>
                        </Nav.Link> */}
                    </Nav>
                </div>
            </Collapse>
        </>
    );
};

export default AnalyticsDropdown;
