// index.js
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './themes.css';
import App from './App';
import { ThemeProvider } from './ThemeContext';
import reportWebVitals from './reportWebVitals';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';

ReactDOM.render(
    <React.StrictMode>
        <I18nextProvider i18n={i18n}>
            <ThemeProvider>
                <App />
            </ThemeProvider>
        </I18nextProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

reportWebVitals();
