import React, { useState, useEffect } from 'react';
import { Container, Form, Button, InputGroup, FormControl } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey, faUser } from '@fortawesome/free-solid-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Login.css';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { PiPepperBold } from "react-icons/pi";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { domain_ip } from './config';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const [messageColor, setMessageColor] = useState('red'); 
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      navigate('/dashboard');
    }
  }, [navigate]);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${domain_ip}login`, {
        username,
        password,
      }, { withCredentials: true });
      if (response.data.token) {
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('username', username);
        localStorage.setItem('password', password);
        localStorage.setItem('loginSuccess', 'true');  // Set the flag
        console.log("Login successful, navigating to dashboard...");  // Debug log
        navigate('/dashboard'); 
      }
      setMessage(response.data.message);
      setMessageColor('green');
    } catch (error) {
      setMessage(error.response ? error.response.data.message : 'Login failed');
      setMessageColor('red');
    }
  };

  return (
    <Container className="centered-container" style={{ fontFamily: 'Kanit, sans-serif', fontWeight: 700 }}>
      <ToastContainer />
      <div className="centered-box">
        <div>
          <h1 className="text-login" style={{ marginBottom: '30px' }}>
            <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
              <PiPepperBold style={{ color: 'red', marginRight: '20px' }} />
              HotWall
            </Link>
          </h1>
        </div>
        <Form onSubmit={handleLogin}>
          <Form.Group controlId="formUsername">
            <InputGroup className="mb-3" style={{ width: '500px' }}>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faUser} style={{ color: 'red' }} />
              </InputGroup.Text>
              <div className="input-divider"></div>
              <FormControl
                type="text"
                placeholder="Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                className="custom-input"
                style={{ fontFamily: 'Kanit, sans-serif', fontWeight: 700 }}
              />
            </InputGroup>
          </Form.Group>
          <Form.Group controlId="formPassword">
            <InputGroup className="mb-3" style={{ width: '500px' }}>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faKey} style={{ color: 'red' }} />
              </InputGroup.Text>
              <div className="input-divider"></div>
              <FormControl
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="custom-input"
                style={{ fontFamily: 'Kanit, sans-serif', fontWeight: 700 }}
              />
            </InputGroup>
          </Form.Group>
          <Button variant="danger" type="submit" style={{ width: '100%', marginTop: '30px' }}>
            Login
          </Button>
          {message && <p style={{ marginTop: '20px', color: messageColor }}>{message}</p>}
          <h2 className="center" style={{ marginTop: '30px', fontFamily: 'Kanit, sans-serif', fontWeight: 700, fontSize: '20px' }}>
            Not a Member yet? <Link to="/signup" className="no-underline" style={{ fontFamily: 'Kanit, sans-serif', fontWeight: 700 }}>Sign up</Link>
          </h2>
        </Form>
      </div>
    </Container>
  );
};

export default Login;
