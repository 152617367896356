// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
      "Settings": "Settings",
      "General settings hotwall account": "General settings hotwall account",
      "Appearance": "Appearance",
      "Select how you’d like the HotWall Dashboard to appear on this device. Choose from light or dark themes, or opt to sync with your operating system’s settings.": "Select how you’d like the HotWall Dashboard to appear on this device. Choose from light or dark themes, or opt to sync with your operating system’s settings.",
      "Theme": "Theme",
      "Dark": "Dark",
      "Light": "Light",
      "Language Preference": "Language Preference",
      "My preference for the language shown in the dashboard is:": "My preference for the language shown in the dashboard is:",
      "Language": "Language",
      "Websites": "Websites",
      "Select a domain to configure and monitor": "Select a domain to configure and monitor",
      "Search": "Search",
      "Add new domain": "Add new domain",
      "Domain": "Domain",
      "IP Address": "IP Address",
      "You cannot create a domain because the domain limit has been exceeded.": "You cannot create a domain because the domain limit has been exceeded.",
      "Enter the correct domain.": "Enter the correct domain.",
      "Enter a valid IP address.": "Enter a valid IP address.",
      "This domain has already been added.": "This domain has already been added.",
      "Error on attempt to add website.": "Error on attempt to add website.",
      "Exit": "Exit",
      "Add": "Add",
      "Domain successfully added": "Domain successfully added",
      "Dashboard": "Dashboard",
      "general information about the hotwall account": "General information about the hotwall account",
      "Login successful": "Login successful",
      "Discover": "Discover",
      "Explore the HotWall product range and see what’s new": "Explore the HotWall product range and see what’s new",
      "Application Security & Performance": "Application Security & Performance",
      "Accelerate and protect a website or application": "Accelerate and protect a website or application",
      "Check website or application": "Check website or application",
      "Network Security": "Network Security",
      "Protect public or private networks": "Protect public or private networks",
      "Get started": "Get started",
      "Trust & Protect": "Trust & Protect",
      "Many popular sites trust us": "Many popular sites trust us",
      "Who?": "Who?",
      "Domains & Security": "Domains & Security",
      "You can always register domains with us cheaply": "You can always register domains with us cheaply",
      "Register now": "Register now",
      "Account Managment": "Account Managment",
      "Start setting up your account right now!": "Start setting up your account right now!",
      "Check profile": "Check profile",
      "Profile": "Profile",
      "information about the hotwall account": "Information about the hotwall account",
      "Account": "Account",
      "Billing Plan": "Billing Plan",
      "Account Created": "Account Created",
      "Security Center": "Security Center",
      "Scan an IP or domain": "Scan an IP or domain",
      "IP or domain": "IP or domain",
      "Secure": "Secure",
      "IP Information": "IP Information",
      "Resource Name": "Resource Name",
      "IP Range": "IP Range",
      "Provider": "Provider",
      "Organization": "Organization",
      "Country": "Country",
      "Region": "Region",
      "City": "City",
      "Timezone": "Timezone",
      "Local Time": "Local Time",
      "Postal Code": "Postal Code",
      "WHOIS Information": "WHOIS Information",
      "Domain Name": "Domain Name",
      "Registrar": "Registrar",
      "Registration Date": "Registration Date",
      "Expiration Date": "Expiration Date",
      "Updated Date": "Updated Date",
      "Status": "Status",
      "Name Servers": "Name Servers",
      "Domain Registration": "Domain Registration",
      "soon domain register...": "soon domain register...",
      "hotwall account": "hotwall account",
      "Please enter a valid IP address or domain.": "Please enter a valid IP address or domain.",
      "Failed to fetch data. Please try again.": "Failed to fetch data. Please try again.",
      "general information about": "general information about",
      "Unique Visitors": "Unique Visitors"
    }
  },
  ru: {
    translation: {
      "Settings": "Настройки",
      "General settings hotwall account": "Общие настройки учетной записи hotwall",
      "Appearance": "Внешний вид",
      "Select how you’d like the HotWall Dashboard to appear on this device. Choose from light or dark themes, or opt to sync with your operating system’s settings.": "Выберите, как вы хотите, чтобы панель управления HotWall выглядела на этом устройстве. Выберите светлую или темную тему или настройте синхронизацию с настройками операционной системы.",
      "Theme": "Тема",
      "Dark": "Темная",
      "Light": "Светлая",
      "Language Preference": "Языковые предпочтения",
      "My preference for the language shown in the dashboard is:": "Мой предпочтительный язык для отображения на панели:",
      "Language": "Язык",
      "Websites": "Веб-сайты",
      "Select a domain to configure and monitor": "Выберите домен для настройки и мониторинга",
      "Search": "Поиск",
      "Add new domain": "Добавить новый домен",
      "Domain": "Домен",
      "IP Address": "IP адрес",
      "You cannot create a domain because the domain limit has been exceeded.": "Вы не можете создать домен, так как превышен лимит доменов.",
      "Enter the correct domain.": "Введите правильный домен.",
      "Enter a valid IP address.": "Введите правильный IP адрес.",
      "This domain has already been added.": "Этот домен уже был добавлен.",
      "Error on attempt to add website.": "Ошибка при попытке добавить веб-сайт.",
      "Exit": "Выход",
      "Add": "Добавить",
      "Domain successfully added": "Домен успешно добавлен",
      "Dashboard": "Панель управления",
      "general information about the hotwall account": "Общая информация об учетной записи hotwall",
      "Login successful": "Вход выполнен успешно",
      "Discover": "Открыть",
      "Explore the HotWall product range and see what’s new": "Изучите ассортимент продукции HotWall и узнайте, что нового",
      "Application Security & Performance": "Безопасность и производительность приложений",
      "Accelerate and protect a website or application": "Ускорьте и защитите веб-сайт или приложение",
      "Check website or application": "Проверьте веб-сайт или приложение",
      "Network Security": "Сетевая безопасность",
      "Protect public or private networks": "Защита публичных или частных сетей",
      "Get started": "Начать",
      "Trust & Protect": "Доверие и защита",
      "Many popular sites trust us": "Многие популярные сайты доверяют нам",
      "Who?": "Кто?",
      "Domains & Security": "Домены и безопасность",
      "You can always register domains with us cheaply": "Вы всегда можете зарегистрировать домены у нас по низким ценам",
      "Register now": "Зарегистрируйтесь сейчас",
      "Account Managment": "Управление учетной записью",
      "Start setting up your account right now!": "Начните настраивать свою учетную запись прямо сейчас!",
      "Check profile": "Проверить профиль",
      "Profile": "Профиль",
      "information about the hotwall account": "Информация об учетной записи hotwall",
      "Account": "Учетная запись",
      "Billing Plan": "Тарифный план",
      "Account Created": "Учетная запись создана",
      "Security Center": "Центр безопасности",
      "Scan an IP or domain": "Сканировать IP или домен",
      "IP or domain": "IP или домен",
      "Secure": "Защитить",
      "IP Information": "Информация об IP",
      "Resource Name": "Имя ресурса",
      "IP Range": "Диапазон IP",
      "Provider": "Провайдер",
      "Organization": "Организация",
      "Country": "Страна",
      "Region": "Регион",
      "City": "Город",
      "Timezone": "Часовой пояс",
      "Local Time": "Местное время",
      "Postal Code": "Почтовый индекс",
      "WHOIS Information": "Информация WHOIS",
      "Domain Name": "Имя домена",
      "Registrar": "Регистратор",
      "Registration Date": "Дата регистрации",
      "Expiration Date": "Дата истечения срока",
      "Updated Date": "Дата обновления",
      "Status": "Статус",
      "Name Servers": "Имя серверов",
      "Domain Registration": "Регистрация домена",
      "soon domain register...": "скоро регистрация доменов...",
      "hotwall account": "учетная запись hotwall",
      "Please enter a valid IP address or domain.": "Пожалуйста, введите действительный IP-адрес или домен.",
      "Failed to fetch data. Please try again.": "Не удалось получить данные. Пожалуйста, попробуйте еще раз.",
      "general information about": "Основная информация о домене",
      "Unique Visitors": "Уникальные посетители"
    }
  }
};

const language = localStorage.getItem('language') || 'en';

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: language, // Установка языка по умолчанию
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
