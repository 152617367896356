import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import MenuNavigation from './DomainMenu';
import ChartComponent from './pages/domain/graphs/ChartComponentOverview';
import { MdLeaderboard } from "react-icons/md";
import ThemeContext from './ThemeContext';
import './Dashboard.css';
import { useTranslation } from 'react-i18next';
import MapTraffic from './pages/domain/MapTraffic';
import WebTraffic from './pages/domain/WebTraffic';

const TrafficPage = () => {
    const { t } = useTranslation();
    const { username, domain } = useParams();
    const { theme } = useContext(ThemeContext);

    const textStyle = {
        color: theme === 'dark' ? 'white' : 'black',
        };

    return (
        <Container fluid className={`dashboard-container ${theme}`}>
            <Row className="flex-grow-1">
                <Col xs={12} md={2} className={`border vh-100 ${theme}`} style={{ overflowY: 'auto' }}>
                    <MenuNavigation />
                </Col>
                <Col xs={12} md={10} className={`border vh-100 ${theme}`} style={{ overflowY: 'auto' }}>
                    <Container className='left-align-container'>
                        <h2 className={theme} style={{ display: 'flex', alignItems: 'center' }}>
                            <MdLeaderboard style={{ marginRight: '10px', fontSize: '22px', marginBottom: '5px', color: 'red' }} />
                            {domain} <span className={theme} style={{ fontSize: '18px', color: 'gray', marginLeft: '10px' }}>hotwall@{username}'s account</span>
                        </h2>
                        <h6 className={theme}>{t('traffic information ')} {domain}</h6>
                        <WebTraffic />
                        <h4 style={textStyle}>Web Traffic Requests by Country</h4>
                        <MapTraffic />
                    </Container>
                </Col>
            </Row>
        </Container>
    );
};

export default TrafficPage;
