import React, { useContext } from 'react';
import { Nav } from 'react-bootstrap';
import { FaFire } from 'react-icons/fa6';
import { FaRegUserCircle } from "react-icons/fa";
import { IoSettingsSharp } from "react-icons/io5";
import { MdDashboard } from "react-icons/md";
import { MdAlignVerticalBottom } from "react-icons/md";
import { GrDomain } from "react-icons/gr";
import { AiFillSecurityScan } from "react-icons/ai";
import { MdLeaderboard } from "react-icons/md";
import { Link } from 'react-router-dom';
import './themes.css'; // Импортируем файл тем
import ThemeContext from './ThemeContext';

const MenuNavigation = () => {
    const { theme } = useContext(ThemeContext);

    return (
        <Nav className={`flex-column ${theme}`}>
            <Nav.Item className={`d-flex position-flex-right ${theme}`} style={{ fontSize: '20px', margin: '15px', marginBottom: '50px' }}>
                <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
                    <FaFire style={{ marginRight: '10px', fontSize: '24px', marginBottom: '5px', color: 'red' }} />
                    HotWall
                </Link>
            </Nav.Item>
            <Nav.Link as={Link} to="/dashboard" className={`d-flex align-items-center ${theme}`}>
                <MdLeaderboard style={{ marginRight: '10px', fontSize: '22px', marginBottom: '5px', color: 'red' }} />
                <span className={`menu-text ${theme}`}>Dashboard</span>
            </Nav.Link>
            <Nav.Link as={Link} to="/websites" className={`d-flex align-items-center ${theme}`}>
                <MdDashboard style={{ marginRight: '10px', fontSize: '22px', marginBottom: '5px', color: 'red' }} />
                <span className={`menu-text ${theme}`}>Websites</span>
            </Nav.Link>
            <Nav.Link as={Link} to="/discover" className={`d-flex align-items-center ${theme}`}>
                <MdAlignVerticalBottom style={{ marginRight: '10px', fontSize: '22px', marginBottom: '5px', color: 'red' }} />
                <span className={`menu-text ${theme}`}>Discover</span>
            </Nav.Link>
            <Nav.Link as={Link} to="/registration-domains" className={`d-flex align-items-center ${theme}`}>
                <GrDomain style={{ marginRight: '10px', fontSize: '22px', marginBottom: '5px', color: 'red' }} />
                <span className={`menu-text ${theme}`}>Domain Registration</span>
            </Nav.Link>
            <Nav.Link as={Link} to="/security-center" className={`d-flex align-items-center ${theme}`}>
                <AiFillSecurityScan style={{ marginRight: '10px', fontSize: '22px', marginBottom: '5px', color: 'red' }} />
                <span className={`menu-text ${theme}`}>Security Center</span>
            </Nav.Link>
            <hr className={`menu-divider ${theme}`} />
            <Nav.Link as={Link} to="/profile" className={`d-flex align-items-center ${theme}`}>
                <FaRegUserCircle style={{ marginRight: '10px', fontSize: '22px', marginBottom: '5px', color: 'red' }} />
                <span className={`menu-text ${theme}`}>Manage Account</span>
            </Nav.Link>
            <Nav.Link as={Link} to="/settings" className={`d-flex align-items-center ${theme}`}>
                <IoSettingsSharp style={{ marginRight: '10px', fontSize: '22px', marginBottom: '5px', color: 'red' }} />
                <span className={`menu-text ${theme}`}>Settings</span>
            </Nav.Link>
        </Nav>
    );
};

export default MenuNavigation;
