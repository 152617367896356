import React, { useEffect, useState, useContext } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import { Icon, Button } from 'semantic-ui-react';
import 'react-toastify/dist/ReactToastify.css';
import 'semantic-ui-css/semantic.min.css';
import './Dashboard.css'; 
import './Menu.js';
import './Login.css';
import { MdLeaderboard } from "react-icons/md";
import MenuNavigation from './Menu.js';
import { useTranslation } from 'react-i18next';
import ThemeContext from './ThemeContext';
import { domain_ip } from './config';
import axios from 'axios';

const Dashboard = () => {
    const { t } = useTranslation();
    const username = localStorage.getItem('username');
    const password = localStorage.getItem('password');
    const { theme } = useContext(ThemeContext);

    const [showWarning, setShowWarning] = useState(false);
    const [bannerData, setBannerData] = useState({
        text: 'We have some problems',
        type: 'warning',
        active: true
    });

    useEffect(() => {
        const loginSuccess = localStorage.getItem('loginSuccess');
        if (loginSuccess === 'true') {
            toast.success(t('Login successful'));
            setTimeout(() => {
                localStorage.removeItem('loginSuccess');
            }, 2000);
        }

        // Fetch banner info from the server
        axios.post(`${domain_ip}info`, { username, password })
            .then(response => {
                const { banner, lastClosedTime } = response.data;
                if (banner) {
                    setBannerData(banner);
                    setShowWarning(banner.active && lastClosedTime === null);
                }
            })
            .catch(error => {
                console.error("There was an error fetching the banner info!", error);
            });
    }, [t, username, password]);

    const handleDismiss = () => {
        setShowWarning(false);
        axios.post(`${domain_ip}dismiss_banner`, { username, password })
            .then(response => {
                console.log(response.data.message);
            })
            .catch(error => {
                console.error("There was an error dismissing the banner!", error);
            });
    };


    return (
        <Container fluid className={`dashboard-container ${theme}`}>
            <ToastContainer autoClose={1000}/>
            <Row className="flex-grow-1">
                <Col xs={12} md={2} className={`border vh-100 ${theme}`}>
                    <MenuNavigation />
                </Col>
                <Col xs={12} md={10} className={`border vh-100 ${theme}`}>
                    <Container className='left-align-container'>
                        {showWarning && (
                            <div style={{ width: '100%', height: '120px', padding: '10px', backgroundColor: '#fff8e1', border: '1px solid #ffe0b2', borderRadius: '4px', position: 'relative', marginBottom: '20px' }}>
                                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                    <Icon name={bannerData.type === 'warning' ? 'warning circle' : 'info circle'} style={{ marginRight: '10px' }} />
                                    <span style={{ marginTop: '10px', maxWidth: '900px' }}>{bannerData.text}</span>
                                </div>
                                <div style={{ position: 'absolute', bottom: '10px', right: '10px' }}>
                                    <Button onClick={handleDismiss} basic color="red" style={{ marginRight: '10px' }}>
                                        OKAY
                                    </Button>
                                    <Button href="https://t.me/StanlyTor" basic color="gray">
                                        SUPPORT
                                    </Button>
                                </div>
                                <Icon name="close" onClick={handleDismiss} style={{ position: 'absolute', top: '10px', right: '10px', cursor: 'pointer' }} />
                            </div>
                        )}
                        <h2 className={theme} style={{ display: 'flex', alignItems: 'center' }}>
                            <MdLeaderboard style={{ marginRight: '10px', fontSize: '22px', marginBottom: '5px', color: 'red' }} />
                            {t('Dashboard')} <span className={theme} style={{ fontSize: '18px', color: 'gray', marginLeft: '10px' }}>hotwall@{username}'s account</span>
                        </h2>
                        <h6 className={theme}>{t('general information about the hotwall account')}</h6>
                    </Container>
                </Col>
            </Row>
        </Container>
    );
};

export default Dashboard;
