import React from 'react';
import './EventsStyle.css';
import { IoMdBook } from "react-icons/io";

const BadgeCustom = ({ text }) => {
  return (
    <div className="custom-badge">
      <span className="icon"><IoMdBook style={{color: '#bababa'}}/></span>
      <span className="text" style={{color: '#bababa'}}>{text}</span>
    </div>
  );
};

export default BadgeCustom;
