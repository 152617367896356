import React, { useState, useEffect, useContext } from 'react';
import { Container, Row, Col, FormControl, InputGroup, Button, Card, Modal, Form } from 'react-bootstrap';
import { FaSearch, FaCheckCircle, FaPlus, FaFire, FaTimes } from 'react-icons/fa';
import axios from 'axios';
import MenuNavigation from './Menu.js';
import './Dashboard.css';
import './Login.css';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MdOutlineError } from "react-icons/md";
import { useTranslation } from 'react-i18next';
import ThemeContext from './ThemeContext';
import { domain_ip } from './config';
import { IoMdHelp } from "react-icons/io";
import CopyText from './assets/CopyText.js';

const Websites = () => {
    const { t } = useTranslation();
    const notify = () => toast.success(t("Domain successfully added"));
    const notifyDelete = () => toast.error(t("Domain successfully deleted"));

    const token = localStorage.getItem('token');
    const username = localStorage.getItem('username');
    const password = localStorage.getItem('password');
    const navigate = useNavigate();
    const { theme } = useContext(ThemeContext);

    const [searchTerm, setSearchTerm] = useState('');
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [domainLimit, setDomainLimit] = useState(0);
    const [newIP, setNewIP] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [showModalT, setShowModalT] = useState(false);
    const [newDomain, setNewDomain] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [websitesUser, setWebsitesUser] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.post(`${domain_ip}websites`, {
                    username: username,
                    password: password
                });
                const websitesUser = response.data.websites;
                setWebsitesUser(websitesUser);
                const websites = response.data.websites.split(',').filter(site => site);
                const domainLimit = response.data.domains_limit;
                setDomainLimit(domainLimit);
                setData(websites);
                setFilteredData(websites);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [username, password]);

    const handleSearch = (event) => {
        const term = event.target.value.toLowerCase();
        setSearchTerm(term);
        const filtered = data.filter(item => item.toLowerCase().includes(term));
        setFilteredData(filtered);
    };

    const handleCardClick = (domain) => {
        const currentUser = localStorage.getItem('username');
        if (currentUser === username) {
            navigate(`/${currentUser}/${domain}/overview`);
        }
    };

    const handleRemoveClick = (domain) => {
        let updatedWebsites = data.filter(item => item !== domain);
        let updatedWebsitesUser = updatedWebsites.join(',') + (updatedWebsites.length ? ',' : '');
        
        setData(updatedWebsites);
        setFilteredData(updatedWebsites);
        setWebsitesUser(updatedWebsitesUser);   
    
        axios.post(`${domain_ip}remove_websites`, {
            username: username,
            password: password,
            domain_user: domain,
            websites: updatedWebsitesUser
        })
        .then(response => {
            if (response.data.message !== 'WebSites updated') {
                setErrorMessage(t('Error on attempt to remove website.'));
            }
            notifyDelete();
        })
        .catch(error => {
            setErrorMessage(t('Error on attempt to remove website.'));
            console.error('Error updating websites:', error);
        });
    };
    
    const handleAddDomain = async () => {
        if (data.length >= domainLimit) {
            setErrorMessage(t('You cannot create a domain because the domain limit has been exceeded.'));
            return;
        }
    
        if (!/^(?!-)[A-Za-z0-9-]+(\.[A-Za-z0-9-]+)*(\.[A-Za-z]{2,})$/.test(newDomain)) {
            setErrorMessage(t('Enter the correct domain.'));
            return;
        }
    
        if (!/^(25[0-5]|2[0-4][0-9]|[0-1]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[0-1]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[0-1]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[0-1]?[0-9][0-9]?)$/.test(newIP)) {
            setErrorMessage(t('Enter a valid IP addresss.'));
            return;
        }
    
        if (data.includes(newDomain)) {
            setErrorMessage(t('This domain has already been added.'));
            return;
        }
    
        const newWebsitesUser = websitesUser + newDomain + ',';
        console.log('Adding new domain:', newDomain);
        console.log('IP address:', newIP);
        console.log('Updated websites list:', newWebsitesUser);
    
        try {
            const response = await axios.post(`${domain_ip}update_websites`, {
                username: username,
                password: password,
                websites: newWebsitesUser,
                ip_address: newIP  // Send IP address
            });
            if (response.data.message === 'Websites updated') {
                setData([...data, newDomain]);
                setFilteredData([...data, newDomain]);
                setWebsitesUser(newWebsitesUser);
                setShowModal(false);
                notify();
            } else {
                setErrorMessage(t('Error on attempt to add websites.'));
            }
        } catch (error) {
            setErrorMessage(t('Error on attempt to add websitess.'));
            console.error('Error updating websites:', error);
        }
    };
    

    const handleKeyPress = (event) => {
        const charCode = event.charCode;
        if (!(/[a-zA-Z0-9.-]/.test(String.fromCharCode(charCode)))) {
            event.preventDefault();
        }
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setNewDomain('');
        setErrorMessage('');
    };

    const handleCloseModalT = () => {
        setShowModalT(false);
    };

    const cardStyle = {
        backgroundColor: theme === 'dark' ? '#333' : 'white',
        color: theme === 'dark' ? 'white' : 'black',
        border: '1px solid #444',
        paddingRight: '10px',
        paddingLeft: '10px',
        paddingBottom: '10px',
        cursor: 'pointer'
    };

    const closeButtonStyle = {
        position: 'absolute',
        top: '10px',
        right: '10px',
        cursor: 'pointer',
        zIndex: 1
    };

    return (
        <Container fluid className={`dashboard-container ${theme}`}>
            <ToastContainer />
            <Row className="flex-grow-1">
                <Col md={2} className={`border vh-100 ${theme}`} style={{ overflowY: 'auto' }}>
                    <MenuNavigation />
                </Col>
                <Col md={10} className={`border vh-100 ${theme}`} style={{ overflowY: 'auto', overflowX: 'auto' }}>
                    <Container className='left-align-container'>
                        <h2 className={theme}>{t('Websites')} <span style={{color: 'red', fontSize: '20px'}}>{data.length} / {domainLimit}</span> <span className={theme} style={{ fontSize: '18px', color: 'gray', marginLeft: '10px' }}>hotwall@{username}'s account</span></h2>
                        <h6 className={theme}>{t('Select a domain to configure and monitor')}</h6>
                        <InputGroup className="mb-3">
                            <InputGroup.Text>
                                <FaSearch />
                            </InputGroup.Text>
                            <FormControl
                                placeholder={t("Search")}
                                value={searchTerm}
                                onChange={handleSearch}
                                className='custom-input'
                            />
                            <Button variant="danger">{t("Search")}</Button>
                        </InputGroup>
                        <Row>
                            {filteredData.map((item, index) => (
                                <Col md={4} key={index} className="mb-3">
                                    <Card 
                                        style={cardStyle}
                                    >
                                        <FaTimes 
                                            style={closeButtonStyle}
                                            onClick={() => handleRemoveClick(item)}
                                        />
                                        <Card.Body 
                                            onClick={() => handleCardClick(item)}
                                            style={{ width: '600px', height: '60px' }}
                                        >
                                            <Card.Title style={{ fontSize: '16px', marginBottom: '5px' }}>{item}</Card.Title>
                                            <Card.Text style={{ display: 'flex', alignItems: 'center', fontSize: '14px', color: 'green', paddingBottom: '5px' }}>
                                                <FaCheckCircle style={{ marginRight: '5px', marginBottom: '5px' }} />
                                                Active
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                        <Col>
                            <Button
                                variant="danger"
                                className="position-fixed bottom-0 end-0"
                                onClick={() => setShowModal(true)}
                                style={{margin: '50px'}}>
                                <FaPlus />
                            </Button>
                            <Button
                                variant="danger"
                                className="position-fixed bottom-0 end-0"
                                onClick={() => setShowModalT(true)}
                                style={{margin: '50px', marginBottom: '100px'}}>
                                <IoMdHelp />
                            </Button>
                        </Col>
                    </Container>
                </Col>
            </Row>

            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title><FaFire style={{ marginRight: '10px', fontSize: '24px', marginBottom: '5px', color: 'red' }} />{t("Add new domain")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {errorMessage && <div className="alert alert-danger"><MdOutlineError style={{ marginRight: '10px', fontSize: '24px', marginBottom: '5px', color: 'red' }} />{errorMessage}</div>}
                    <Form>
                        <Form.Group controlId="formNewDomain">
                            <Form.Label>{t("Domain")}</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder={t("Type new domain")}
                                value={newDomain}
                                onChange={(e) => setNewDomain(e.target.value)}
                                onKeyPress={handleKeyPress}
                                className="custom-input"
                                maxLength={30}
                            />
                        </Form.Group>
                        <Form.Group controlId="formNewIP">
                            <Form.Label>{t("IP Address")}</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder={t("Type IP address")}
                                value={newIP}
                                onChange={(e) => setNewIP(e.target.value)}
                                className="custom-input"
                                maxLength={60}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-secondary" onClick={handleCloseModal} style={{ width: '100px' }}>
                        {t("Exit")}
                    </Button>
                    <Button variant="outline-danger" onClick={handleAddDomain} style={{ width: '100px' }}>
                        {t("Add")}
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showModalT} onHide={handleCloseModalT}>
        <Modal.Header closeButton>
          <Modal.Title>
            <FaFire
              style={{
                marginRight: '10px',
                fontSize: '24px',
                marginBottom: '5px',
                color: 'red',
              }}
            />
            {t("How add domain?")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {errorMessage && (
            <div className="alert alert-danger">
              <MdOutlineError
                style={{
                  marginRight: '10px',
                  fontSize: '24px',
                  marginBottom: '5px',
                  color: 'red',
                }}
              />
              {errorMessage}
            </div>
          )}
          <Form>
            <h4>
              In order to add a domain, you need to enter your domain and the
              backend of your site in the appropriate fields. Then you need to
              change the IP of the site from your DNS provider to
            </h4>
            <CopyText copys="162.19.139.143" />
            <h4>
              You wait 15 minutes and your site is automatically connected to
              the protection
            </h4>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-secondary"
            onClick={handleCloseModalT}
            style={{ width: '100px' }}
          >
            {t("Exit")}
          </Button>
        </Modal.Footer>
      </Modal>

        </Container>
    );
};

export default Websites;
