import React from 'react';
import { Navbar, Nav, NavDropdown, Form, FormControl, Button } from 'react-bootstrap';
import './NavBar.css';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { useNavigate } from 'react-router-dom';

import { FaFire } from 'react-icons/fa6';
import { CiDollar } from "react-icons/ci";
import { MdDashboard } from "react-icons/md";
import { BiCloud } from "react-icons/bi";

import 'bootstrap/dist/css/bootstrap.min.css';

const HotWallNavbar = () => {
  const navigate = useNavigate();

  const handleLoginClick = () => {
    navigate('/login');
  };
  const handleDashboardClick = () => {
    navigate('/websites');
  };  

  const goToTelegram = () => {
    window.location.href = 'https://t.me/hotwalladmin';
  };

  const goToSup = () => {
    window.location.href = 'https://t.me/StanlyTor';
  };

  return (
    <Navbar bg="light" expand="lg">
      <Navbar.Brand href="#home" className="navbar-brand-padding">
        <FaFire style={{ marginRight: '10px' , fontSize: '24px', marginBottom: '5px', color: 'red'}} />
        HotWall
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav" className='navbar-collapse-padding'>
        <Nav className="justify-content-end flex-grow-1 pe-3">
          <Nav.Link onClick={handleDashboardClick}><MdDashboard style={{ marginRight: '10px' , fontSize: '18px', marginBottom: '5px', color: 'red'}} />Dashboard</Nav.Link>
          <Nav.Link href="#price"><CiDollar style={{ marginRight: '10px' , fontSize: '18px', marginBottom: '5px', color: 'red'}} />Price</Nav.Link>
        </Nav>
        <NavDropdown
              id="nav-dropdown-support"
              title="Support"
              menuVariant="light"
              style={{ marginRight: '15px'}}
            >
              <NavDropdown.Item onClick={goToTelegram}>Administration</NavDropdown.Item>
              <NavDropdown.Item onClick={goToSup}>
                Support
              </NavDropdown.Item>
        </NavDropdown>
        <Form className="d-flex">
          <Button variant='outline-danger' style={{ width: '100px', fontWeight: 'bold'}} onClick={handleLoginClick}><BiCloud className="icon" style={{ marginRight: '10px' , marginBottom: '5px'}}/>Login</Button>
        </Form>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default HotWallNavbar;
