// src/Discover.js
import React, { useContext } from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import './Dashboard.css'; 
import './Menu.js';
import './Login.css';
import './themes.css'; // Import the theme styles
import { MdAlignVerticalBottom } from "react-icons/md";
import { useNavigate } from 'react-router-dom';
import MenuNavigation from './Menu.js';
import { RxLightningBolt } from "react-icons/rx";
import { HiOutlineShieldCheck } from "react-icons/hi2";
import { PiLockKeyBold } from "react-icons/pi";
import { MdOutlineDns, MdOutlineAccountBox } from "react-icons/md";
import { useTranslation } from 'react-i18next';
import ThemeContext from './ThemeContext';

const Discover = () => {
    const { t } = useTranslation();
    const username = localStorage.getItem('username');
    const navigate = useNavigate();
    const { theme } = useContext(ThemeContext);

    const cardStyle = {
        backgroundColor: theme === 'dark' ? '#333' : 'white',
        color: theme === 'dark' ? 'white' : 'black',
        border: '1px solid #444',
        padding: '10px',
        width: '900px',
        maxWidth: '900px',
        margin: '10px auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    };

    const cardOther = {
        backgroundColor: theme === 'dark' ? '#333' : 'white',
        color: theme === 'dark' ? 'white' : 'black',
        border: '1px solid #444',
        padding: '10px',
        width: '450px',
        maxWidth: '450px',
        margin: '10px auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    };

    const cardOther2 = {
        backgroundColor: theme === 'dark' ? '#333' : 'white',
        color: theme === 'dark' ? 'white' : 'black',
        border: '1px solid #444',
        padding: '10px',
        width: '425px',
        maxWidth: '425px',
        margin: '10px auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    };

    const handleSecurityApplication = () => {
        navigate('/websites');
    };

    return (
        <Container fluid className={`dashboard-container ${theme}`}>
            <Row className="flex-grow-1">
                <Col md={2} className={`border vh-100 ${theme}`}>
                    <MenuNavigation />
                </Col>
                <Col md={10} className={`border vh-100 ${theme}`}>
                    <Container className='left-align-container'>
                        <h2 className={theme} style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                            <MdAlignVerticalBottom style={{ marginRight: '10px', fontSize: '22px', marginBottom: '5px', color: 'red' }} />
                            {t('Discover')} <span className={theme} style={{ fontSize: '18px', color: 'gray', marginLeft: '10px' }}>hotwall@{username}'s account</span>
                        </h2>
                        <h6 className={theme}>{t("Explore the HotWall product range and see what’s new")}</h6>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <Card className={theme} style={cardStyle}>
                                <Card.Body className="d-flex flex-column justify-content-center align-items-center">
                                    <RxLightningBolt style={{ marginRight: '10px', fontSize: '52px', marginBottom: '15px', color: 'red' }} />
                                    <Card.Title style={{ fontSize: '16px', marginBottom: '5px' }}>{t("Application Security & Performance")}</Card.Title>
                                    <Card.Text style={{ fontSize: '13px', marginBottom: '35px', textAlign: 'center' }}>{t("Accelerate and protect a website or application")}</Card.Text>
                                    <Button variant='outline-danger' style={{ width: '100%', maxWidth: '400px', fontWeight: 'bold', fontSize: '14px'}} onClick={handleSecurityApplication}>{t("Check website or application")}</Button>
                                </Card.Body>
                            </Card>
                        </div>
                        <Row>
                            <Col>
                                <Card className={theme} style={cardOther}>
                                    <Card.Body className="d-flex flex-column justify-content-center align-items-center">
                                        <HiOutlineShieldCheck style={{ marginRight: '10px', fontSize: '52px', marginBottom: '15px', color: 'red' }} />
                                        <Card.Title style={{ fontSize: '16px', marginBottom: '5px' }}>{t("Network Security")}</Card.Title>
                                        <Card.Text style={{ fontSize: '13px', marginBottom: '35px', textAlign: 'center' }}>{t("Protect public or private networks")}</Card.Text>
                                        <Button variant='outline-danger' style={{ width: '100%', maxWidth: '400px', fontWeight: 'bold', fontSize: '14px'}} onClick={() => navigate('/security-center')}>{t("Get started")}</Button>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col>
                                <Card className={theme} style={cardOther2}>
                                    <Card.Body className="d-flex flex-column justify-content-center align-items-center">
                                        <PiLockKeyBold style={{ marginRight: '10px', fontSize: '52px', marginBottom: '15px', color: 'red' }} />
                                        <Card.Title style={{ fontSize: '16px', marginBottom: '5px' }}>{t("Trust & Protect")}</Card.Title>
                                        <Card.Text style={{ fontSize: '13px', marginBottom: '35px', textAlign: 'center' }}>{t("Many popular sites trust us")}</Card.Text>
                                        <Button variant='outline-danger' style={{ width: '100%', maxWidth: '400px', fontWeight: 'bold', fontSize: '14px'}} onClick={() => navigate('/service-trust')}>{t("Who?")}</Button>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Card className={theme} style={cardOther}>
                                    <Card.Body className="d-flex flex-column justify-content-center align-items-center">
                                        <MdOutlineDns style={{ marginRight: '10px', fontSize: '52px', marginBottom: '15px', color: 'red' }} />
                                        <Card.Title style={{ fontSize: '16px', marginBottom: '5px' }}>{t("Domains & Security")}</Card.Title>
                                        <Card.Text style={{ fontSize: '13px', marginBottom: '35px', textAlign: 'center' }}>{t("You can always register domains with us cheaply")}</Card.Text>
                                        <Button variant='outline-danger' style={{ width: '100%', maxWidth: '400px', fontWeight: 'bold', fontSize: '14px'}} onClick={() => navigate('/registration-domains')}>{t("Register now")}</Button>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col>
                                <Card className={theme} style={cardOther2}>
                                    <Card.Body className="d-flex flex-column justify-content-center align-items-center">
                                        <MdOutlineAccountBox style={{ marginRight: '10px', fontSize: '52px', marginBottom: '15px', color: 'red' }} />
                                        <Card.Title style={{ fontSize: '16px', marginBottom: '5px' }}>{t("Account Management")}</Card.Title>
                                        <Card.Text style={{ fontSize: '13px', marginBottom: '35px', textAlign: 'center' }}>{t("Start setting up your account right now!")}</Card.Text>
                                        <Button variant='outline-danger' style={{ width: '100%', maxWidth: '400px', fontWeight: 'bold', fontSize: '14px'}} onClick={() => navigate('/profile')}>{t("Check profile")}</Button>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </Col>
            </Row>
        </Container>
    );
};

export default Discover;
