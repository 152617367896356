// src/DomainRegitration.js
import React, { useContext } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './Dashboard.css'; 
import './Menu.js';
import './Login.css';
import { GrDomain } from "react-icons/gr";
import MenuNavigation from './Menu.js';
import { useTranslation } from 'react-i18next';
import ThemeContext from './ThemeContext';

const DomainRegitration = () => {
    const { t } = useTranslation();
    const token = localStorage.getItem('token');
    const username = localStorage.getItem('username');
    const { theme } = useContext(ThemeContext);

    return (
        <Container fluid className={`dashboard-container ${theme}`}>
            <Row className="flex-grow-1">
                <Col md={2} className={`border vh-100 ${theme}`}>
                    <MenuNavigation />
                </Col>
                <Col md={10} className={`border vh-100 ${theme}`}>
                    <Container className='left-align-container'>
                        <h2 className={theme} style={{ display: 'flex', alignItems: 'center' }}>
                            <GrDomain style={{ marginRight: '10px', fontSize: '22px', marginBottom: '5px', color: 'red' }} />
                            {t('Domain Registration')} <span className={theme} style={{ fontSize: '18px', color: 'gray', marginLeft: '10px' }}>hotwall@{username}'s account</span>
                        </h2>
                        <h6 className={theme}>{t('soon domain register...')}</h6>
                    </Container>
                </Col>
            </Row>
        </Container>
    );
};

export default DomainRegitration;
