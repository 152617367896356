// WebTraffic.js
import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { Navbar, Container } from 'react-bootstrap';
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ThemeContext from './../../ThemeContext';

const fetchData = async () => {
  // Extract account and domain from the URL path
  const pathParts = window.location.pathname.split('/');
  const account = pathParts[1]; // Adjust the index based on your URL structure
  const domain = pathParts[2]; // Adjust the index based on your URL structure

  const url = `https://${domain}/vm/api/v1/metric?ResultCounter=2880`;

  try {
    const response = await fetch(url);
    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }
    const result = await response.json();
    console.log('Fetched data:', result.data.viewer.series);
    return result.data.viewer.series;
  } catch (error) {
    console.error('Error fetching data:', error);
    toast.error(`${domain} error get chart`);
    return [];
  }
};

const aggregateData = (data) => {
  if (!data || data.length === 0) {
    console.error('No data to aggregate');
    return [];
  }

  const aggregatedData = [];
  const pointsPerHour = Math.floor(data.length / 24);

  for (let i = 0; i < 24; i++) {
    const hourData = data.slice(i * pointsPerHour, (i + 1) * pointsPerHour);
    const total = hourData.reduce((sum, point) => sum + point.metric.total, 0);
    const cached = hourData.reduce((sum, point) => sum + point.metric.cached, 0);
    const uncached = hourData.reduce((sum, point) => sum + (point.metric.total - point.metric.cached), 0);
    aggregatedData.push({
      name: `${i}:00`,
      'Total Requests': total,
      CachedRequests: cached,
      UncachedRequests: uncached,
    });
  }

  console.log('Aggregated data:', aggregatedData);
  return aggregatedData;
};

const WebTraffic = () => {
  const [data, setData] = useState([]);
  const { theme } = useContext(ThemeContext);

  useEffect(() => {
    const getData = async () => {
      const rawData = await fetchData();
      if (rawData.length > 0) {
        const aggregatedData = aggregateData(rawData);
        setData(aggregatedData);
      }
    };

    getData();
  }, []);

  const textStyle = {
    color: theme === 'dark' ? 'white' : 'black',
    };  

  return (
    <div className="chart-container" style={{ padding: '20px', width: '100%', maxWidth: '1600px', margin: '0 auto', textAlign: 'left' }}>
      <h4 style={textStyle}>Requests Through</h4>
      <ResponsiveContainer width="100%" height={400}>
        <LineChart data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey="Total Requests" name="Total Requests" stroke="#8884d8" />
          <Line type="monotone" dataKey="CachedRequests" name="Cached Requests" stroke="#82ca9d" />
          <Line type="monotone" dataKey="UncachedRequests" name="Uncached Requests" stroke="#ff7300" />
        </LineChart>
      </ResponsiveContainer>
      <ToastContainer />
    </div>
  );
};

export default WebTraffic;
