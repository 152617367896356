import React, { createContext, useState } from 'react';

const MenuContext = createContext();

export const MenuProvider = ({ children }) => {
    const [openDropdown, setOpenDropdown] = useState('');
    const [activePath, setActivePath] = useState('');

    return (
        <MenuContext.Provider value={{ openDropdown, setOpenDropdown, activePath, setActivePath }}>
            {children}
        </MenuContext.Provider>
    );
};

export default MenuContext;
