import React, { createContext, useState, useEffect } from 'react';

const ThemeContext = createContext();

const DEFAULT_THEME = 'light';

export const ThemeProvider = ({ children }) => {
    const [theme, setTheme] = useState(() => {
        // Проверяем путь и устанавливаем тему в зависимости от него
        const path = window.location.pathname;
        return path === '/' ? 'light' : (localStorage.getItem('theme') || DEFAULT_THEME);
    });

    useEffect(() => {
        document.body.className = theme;
        localStorage.setItem('theme', theme);
    }, [theme]);

    return (
        <ThemeContext.Provider value={{ theme, setTheme }}>
            {children}
        </ThemeContext.Provider>
    );
};

export default ThemeContext;
