import React, { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FaCopy } from 'react-icons/fa';

const CopyText = ({ copys }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000); // Сбрасывает статус через 2 секунды
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <span>{copys}</span>
      <CopyToClipboard text={copys} onCopy={handleCopy}>
        <button style={{ marginLeft: '10px', background: 'none', border: 'none', cursor: 'pointer' }}>
          <FaCopy />
        </button>
      </CopyToClipboard>
      {copied && <span style={{ marginLeft: '10px', color: 'green' }}>Скопировано!</span>}
    </div>
  );
};

export default CopyText;
