import React from 'react';
import { Card, Icon, Grid, Container, Header } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import PriceCard from './CardPrice';
const TariffsWidget = () => (
    <Container id="price">
      <Header as='h2' textAlign='center' style={{ margin: '20px 0' , paddingTop: "50px", paddingBottom: "50px"}}>
        <Icon name="check circle" color="red" />
        <Header.Content>TARIFFS</Header.Content>
      </Header>
      <Grid centered columns={3}>
        <Grid.Column>
          <PriceCard
            title="Small projects"
            description="Small link aggregators and other projects"
            cost="150"
            domains="3"
            traffic="UNLIMITED"
            uptime="99.99%"
          />
        </Grid.Column>
        <Grid.Column>
          <PriceCard
            title="Standart projects"
            description="Forums / shops and other"
            cost="325"
            domains="5"
            traffic="UNLIMITED"
            uptime="99.99%"
          />
        </Grid.Column>
        <Grid.Column>
          <PriceCard
            title="Big projects"
            description="Marketplaces, big forums and other"
            cost="650"
            domains="15"
            traffic="UNLIMITED"
            uptime="99.99%"
          />
        </Grid.Column>
      </Grid>
    </Container>
  );
  
  export default TariffsWidget;
  