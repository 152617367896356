import React, { useState, useEffect, useContext } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import ThemeContext from '../../../ThemeContext';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const fetchData = async (timeRange) => {
    const pathParts = window.location.pathname.split('/');
    const account = pathParts[1];
    const domain = pathParts[2];

    let resultCounter;
    switch (timeRange) {
        case '30min':
            resultCounter = 120;
            break;
        case '1hour':
            resultCounter = 240;
            break;
        case '24hours':
            resultCounter = 5240;
            break;
        default:
            resultCounter = 30;
    }

    const url = `https://${domain}/vm/api/v1/metric?ResultCounter=${resultCounter}`;

    try {
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const result = await response.json();
        console.log('Fetched data:', result.data.viewer.series);
        return result.data.viewer.series;
    } catch (error) {
        console.error('Error fetching data:', error);
        toast.error(`${domain} error`);
        return [];
    }
};

const aggregateData = (data, timeRange) => {
    if (!data || data.length === 0) {
        console.error('No data to aggregate');
        return [];
    }

    let interval;
    switch (timeRange) {
        case '30min':
            interval = 3;
            break;
        case '1hour':
            interval = 10;
            break;
        case '24hours':
            interval = 30;
            break;
        default:
            interval = 3;
    }

    const aggregatedData = data.filter((_, index) => index % interval === 0).map((point) => ({
        name: new Date(point.ts).toLocaleTimeString(),
        Total: point.metric.total,
        Bypassing: point.metric.bypassing,
        Cached: point.metric.cached,
        WhiteBots: point.metric.whitebots,
        Suspicious: point.metric.suspicious,
        Blocked: point.metric.blocked
    }));

    console.log('Aggregated data:', aggregatedData);
    return aggregatedData;
};

const formatNumber = (number) => {
    if (number >= 1e9) {
        return (number / 1e9).toFixed(2) + 'B';
    } else if (number >= 1e6) {
        return (number / 1e6).toFixed(2) + 'M';
    } else if (number >= 1e3) {
        return (number / 1e3).toFixed(2) + 'k';
    } else {
        return number.toString();
    }
};

const CustomTooltip = ({ active, payload, label, theme }) => {
    if (active && payload && payload.length) {
        return (
            <div style={{ backgroundColor: theme === 'dark' ? '#555' : '#e2e2e2', borderColor: theme === 'dark' ? '#aaa' : '#333', color: theme === 'dark' ? 'white' : 'black', fontSize: '13px', borderRadius: '15px', padding: '10px' }}>
                <p>{label}</p>
                {payload.map((entry, index) => (
                    entry.value !== 0 && (
                        <p key={`item-${index}`} style={{ display: 'flex', alignItems: 'center' }}>
                            <span style={{ display: 'inline-block', width: '10px', height: '10px', backgroundColor: entry.color, borderRadius: '50%', border: '1px solid black', marginRight: '5px' }}></span>
                            {`${entry.name} : ${entry.value}`}
                        </p>
                    )
                ))}
            </div>
        );
    }

    return null;
};

const EventChart = ({ timeRange }) => {
    const [data, setData] = useState([]);
    const { theme } = useContext(ThemeContext);

    useEffect(() => {
        const getData = async () => {
            const rawData = await fetchData(timeRange);
            if (rawData.length > 0) {
                const aggregatedData = aggregateData(rawData, timeRange);
                setData(aggregatedData);
            }
        };

        getData();
    }, [timeRange]);

    const textStyle = {
        color: theme === 'dark' ? 'white' : 'black',
    };

    const xAxisInterval = timeRange === '24hours' ? 10 : 'preserveStartEnd';

    const customTickFormatter = (value, index) => {
        if (index === 0 || index === data.length - 1) {
            return '';
        }
        return value;
    };

    const hasData = (key) => data.some(point => point[key] !== 0);

    return (
        <div className={`chart-container ${theme}`} style={{ backgroundColor: theme === 'dark' ? '#333' : 'white', padding: '20px', color: theme === 'dark' ? 'white' : 'black', width: '100%', margin: '5 auto', textAlign: 'left' }}>
            <ResponsiveContainer width="100%" height={400}>
                <LineChart data={data}>
                    <CartesianGrid stroke={theme === 'dark' ? '#ccc' : '#444'} />
                    <XAxis dataKey="name" tickFormatter={customTickFormatter} interval={xAxisInterval} label={{ value: "Time Local", position: 'insideBottom', offset: -5, fill: textStyle.color }} stroke={theme === 'dark' ? 'white' : 'black'} />
                    <YAxis stroke={theme === 'dark' ? 'white' : 'black'} tickFormatter={formatNumber} />
                    <Tooltip content={<CustomTooltip theme={theme} />} />
                    {hasData('Total') && <Line type="monotone" dataKey="Total" stroke="#9067b6" legendType="none" dot={{ fill: theme === 'dark' ? '#333' : '#fff' }} activeDot={{ r: 8 }} />}
                    {hasData('Bypassing') && <Line type="monotone" dataKey="Bypassing" stroke="#8884d8" legendType="none" dot={{ fill: theme === 'dark' ? '#333' : '#fff' }} activeDot={{ r: 8 }} />}
                    {hasData('Cached') && <Line type="monotone" dataKey="Cached" stroke="#82ca9d" legendType="none" dot={{ fill: theme === 'dark' ? '#333' : '#fff' }} activeDot={{ r: 8 }} />}
                    {hasData('WhiteBots') && <Line type="monotone" dataKey="WhiteBots" stroke="#ffc658" legendType="none" dot={{ fill: theme === 'dark' ? '#333' : '#fff' }} activeDot={{ r: 8 }} />}
                    {hasData('Suspicious') && <Line type="monotone" dataKey="Suspicious" stroke="#ff7300" legendType="none" dot={{ fill: theme === 'dark' ? '#333' : '#fff' }} activeDot={{ r: 8 }} />}
                    {hasData('Blocked') && <Line type="monotone" dataKey="Blocked" stroke="#ff0000" legendType="none" dot={{ fill: theme === 'dark' ? '#333' : '#fff' }} activeDot={{ r: 8 }} />}
                </LineChart>
            </ResponsiveContainer>
            <ToastContainer />
        </div>
    );
};

export default EventChart;
