import React, { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Row, Col, Dropdown, Badge } from 'react-bootstrap';
import MenuNavigation from './DomainMenu';
import { MdLeaderboard } from "react-icons/md";
import ThemeContext from './ThemeContext';
import './Dashboard.css';
import { useTranslation } from 'react-i18next';
import BadgeCustom from './pages/domain/dashboard/Badge.js';
import './pages/domain/dashboard/EventsStyle.css';
import EventChart from './pages/domain/dashboard/EventsChart.js';

const EventsPage = () => {
    const { t } = useTranslation();
    const { username, domain } = useParams();
    const { theme } = useContext(ThemeContext);
    const [timeRange, setTimeRange] = useState('30min');

    const textStyle = {
        color: theme === 'dark' ? 'white' : 'black',
    };

    const dropdownStyle = {
        backgroundColor: theme === 'dark' ? '#343a40' : '#ffffff',
        color: theme === 'dark' ? 'white' : 'black',
    };

    const liveBadgeStyle = {
        backgroundColor: theme === 'dark' ? '#28a745' : '#28a745',
        color: theme === 'dark' ? 'white' : 'white',
    };

    useEffect(() => {
        const interval = setInterval(() => {
            // Trigger the EventChart to refresh data every 1 minute
            setTimeRange(prevTimeRange => prevTimeRange); // Force re-render by updating the same state
        }, 60000); // 60000ms = 1 minute
        return () => clearInterval(interval);
    }, []);

    return (
        <Container fluid className={`dashboard-container ${theme}`}>
            <Row className="flex-grow-1">
                <Col xs={12} md={2} className={`border vh-100 ${theme}`} style={{ overflowY: 'auto' }}>
                    <MenuNavigation />
                </Col>
                <Col xs={12} md={10} className={`border vh-100 ${theme}`} style={{ overflowY: 'auto' }}>
                    <Container className='left-align-container'>
                        <h2 className={theme} style={{ display: 'flex', alignItems: 'center', ...textStyle }}>
                            <MdLeaderboard style={{ marginRight: '10px', fontSize: '22px', marginBottom: '5px', color: 'red' }} />
                            {domain} <span className={theme} style={{ fontSize: '18px', color: 'gray', marginLeft: '10px' }}>hotwall@{username}'s account</span>
                        </h2>
                        <h6 className={theme} style={textStyle}>{t('events ')} {domain}</h6>
                        <Container className={`border vh-100 ${theme}`} style={{ overflowY: 'auto' }}>
                            <div style={{ display: 'flex', alignItems: 'center' }} className='eventsStyle'>
                                <Badge bg="danger" style={{ margin: '10px', ...liveBadgeStyle }}>Live</Badge>
                                <Dropdown onSelect={(e) => setTimeRange(e)}>
                                    <Dropdown.Toggle variant="secondary" id="dropdown-basic" style={dropdownStyle}>
                                        Previous {timeRange === '30min' ? '30 minutes' : timeRange === '1hour' ? '1 hour' : '24 hours'}
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item eventKey="30min">Previous 30 minutes</Dropdown.Item>
                                        <Dropdown.Item eventKey="1hour">Previous 1 hour</Dropdown.Item>
                                        <Dropdown.Item eventKey="24hours">Previous 24 Hours</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                            <hr className={`menu-divider ${theme}`} />
                            <h2 className={`${theme} eventsStyle`} style={{textStyle, fontSize: "18px"}}>Events Summary<span style={{fontSize: "14px", marginLeft: '10px', paddingBottom: '15px'}}><BadgeCustom text="About Firewall Events" /></span></h2>
                            <div className="action-container">
                                <span className={`${theme} action-text`} style={{textStyle, fontSize: "18px", marginLeft: '20px'}}>Action</span>
                            </div>
                            <div style={{ width: '100%' }}>
                                <EventChart key={timeRange} timeRange={timeRange} />
                            </div>
                        </Container>
                    </Container>
                </Col>
            </Row>
        </Container>
    );
};

export default EventsPage;
