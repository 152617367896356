import React from 'react';
import { Card, Icon, Grid, Container, Header } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
const goToTelegram = () => {
    window.location.href = 'https://t.me/hotwalladmin';
  };
const PriceCard = ({ title, description, cost, domains, traffic, uptime }) => (
    
    <Card style={{ height: '400px', padding: '10px', backgroundColor: '#fff5f5' }}>
      <Card.Content>
        <Card.Header style={{ fontSize: '1.2em', display: 'flex', alignItems: 'center' }}>
          <Icon name="info circle" style={{ marginRight: '8px' }} /> 
          <div>
            <div>{title}</div>
            <Card.Meta style={{ fontSize: '0.9em', marginTop: '4px' }}>
              {description}
            </Card.Meta>
          </div>
        </Card.Header>
      </Card.Content>
      <Card.Content>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
          <span>
            <Icon name="bitcoin" /> COST:
          </span>
          <span>
            <strong style={{ color: 'green' }}>${cost}</strong> / per month
          </span>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
          <span>
            <Icon name="bitcoin" /> DOMAINS:
          </span>
          <span>{domains}</span>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
          <span>
            <Icon name="bitcoin" /> TRAFFIC:
          </span>
          <span>{traffic}</span>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
          <span>
            <Icon name="bitcoin" /> TIME UP:
          </span>
          <span>{uptime}</span>
        </div>
      </Card.Content>
      <Card.Content extra textAlign="center">
        <a style={{ color: 'red', fontSize: '1.1em' }} onClick={goToTelegram}>Buy</a>
      </Card.Content>
    </Card>
  );  
  
export default PriceCard;
  