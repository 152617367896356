import React, { useState, useEffect, useContext } from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import './Dashboard.css'; 
import './Login.css';
import './themes.css'; // Import the theme styles
import MenuNavigation from './Menu.js';
import { FaRegUserCircle } from "react-icons/fa";
import axios from 'axios';
import ThemeContext from './ThemeContext';
import { domain_ip } from './config';
import {FaCheckCircle, FaPlus } from 'react-icons/fa';
import { BiSupport } from "react-icons/bi";

const Profile = () => {
    const token = localStorage.getItem('token');
    const username = localStorage.getItem('username');
    const password = localStorage.getItem('password');
    const { theme } = useContext(ThemeContext);

    const [billing, setBilling] = useState('');
    const [created, setCreated] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.post(`${domain_ip}profile`, {
                    username,
                    password,
                }, { withCredentials: true });

                if (response.data.billing == 'Standart') {
                    setBilling(response.data.billing+' \\ $ 325');
                } else if (response.data.billing == 'Enterprice') {
                    setBilling(response.data.billing+' \\ $ 650');
                } else if (response.data.billing == 'Small') {
                    setBilling(response.data.billing+' \\ $ 155');
                }
                setCreated(response.data.created);
            } catch (error) {
                console.error("Error fetching data", error);
            }
        };

        fetchData();
    }, [username, password]);   

    const cardStyle = {
        backgroundColor: theme === 'dark' ? '#333' : 'white',
        color: theme === 'dark' ? 'white' : 'black',
        border: '1px solid #444',
        paddingRight: '10px',
        paddingLeft: '10px',
        paddingBottom: '10px',
        width: '400px',
        height: '80px',
        margin: '10px'
    };

    return (
        <Container fluid className={`dashboard-container ${theme}`}>
            <Row className="flex-grow-1">
                <Col md={2} className={`border vh-100 ${theme}`}>
                    <MenuNavigation />
                </Col>
                <Col md={10} className={`border vh-100 ${theme}`}>
                    <Container className='left-align-container'>
                        <h2 className={theme} style={{ display: 'flex', alignItems: 'center' }}>
                            <FaRegUserCircle style={{ marginRight: '10px', fontSize: '22px', marginBottom: '5px', color: 'red' }} />
                            Profile <span className={theme} style={{ fontSize: '18px', color: 'gray', marginLeft: '10px' }}>hotwall@{username}'s account</span>
                        </h2>
                        <h6 className={theme}>Information about the hotwall account</h6>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Card style={cardStyle}>
                                <Card.Body>
                                    <Card.Title style={{ fontSize: '16px', marginBottom: '5px' }}>Account</Card.Title>
                                    <Card.Text style={{ display: 'flex', alignItems: 'center', fontSize: '14px', color: 'green', paddingBottom: '5px' }}>
                                        <FaCheckCircle style={{ marginRight: '5px', marginBottom: '5px' }} />
                                        {username}
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                            <Card style={cardStyle}>
                                <Card.Body>
                                    <Card.Title style={{ fontSize: '16px', marginBottom: '5px' }}>Billing Plan</Card.Title>
                                    <Card.Text style={{ display: 'flex', alignItems: 'center', fontSize: '14px', color: 'green', paddingBottom: '5px' }}>
                                        <FaCheckCircle style={{ marginRight: '5px', marginBottom: '5px' }} />
                                        {billing}
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                            <Card style={cardStyle}>
                                <Card.Body>
                                    <Card.Title style={{ fontSize: '16px', marginBottom: '5px' }}>Account Created</Card.Title>
                                    <Card.Text style={{ display: 'flex', alignItems: 'center', fontSize: '14px', color: 'green', paddingBottom: '5px' }}>
                                        <FaCheckCircle style={{ marginRight: '5px', marginBottom: '5px' }} />
                                        {created}
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </div>
                        <Col>
                            <Button
                                variant="danger"
                                className="position-fixed bottom-0 end-0"
                                onClick={() => window.open("https://t.me/hotwalladmin", "_blank")}
                                style={{margin: '50px', marginBottom: '50px', fontSize: '20px'}}>
                                <BiSupport />
                            </Button>
                        </Col>
                    </Container>
                </Col>
            </Row>
        </Container>
    );
};

export default Profile;
