import React from 'react';
import { Container, Row, Col, Image, Button } from 'react-bootstrap';
import hotwallImage from './assets/images/hotwall.png';

import { GrShieldSecurity } from "react-icons/gr";
import { BiCloud } from "react-icons/bi";
import { FiCloudLightning } from "react-icons/fi";
import { FaArrowDownLong } from "react-icons/fa6";

const ImageWithText = () => {
  const goToTelegram = () => {
    window.location.href = 'https://t.me/hotwalladmin';
  };
  return (
    <Container className="position-relative" style={{ maxWidth: '800px', marginTop: '100px'}} id="home">
      <img src={hotwallImage} alt="Hotwall" className="img-fluid" />
      <div className="position-absolute top-0 start-0 p-2" style={{ marginLeft: '-200px' }}>
        <div className="bg-white p-1 top-0 start-200 text-start" style={{fontSize: '20px'}}>
          <BiCloud style={{fontSize: '40px', margin: '10px', color: 'red'}}/>Control
        </div>
        <div className="bg-white p-1" style={{marginBottom: '30px', width: '300px', fontSize: '15px'}}>
          Regain visibility and control of IT and security across on-prem, public cloud, SaaS, and the Internet
        </div>
      </div>
      <div className="position-absolute top-0 end-0 p-2" style={{ marginRight: '-200px' }}>
        <div className="bg-white p-1 top-0 start-200 text-start" style={{fontSize: '20px'}}>
          <GrShieldSecurity style={{fontSize: '40px', margin: '10px', color: 'red'}}/>Security
        </div>
        <div className="bg-white p-1" style={{marginBottom: '30px', width: '300px', fontSize: '15px'}}>
          Improve security and resilience while reducing your attack surface, vendor count, and tool sprawl
        </div>
      </div>
      <div className="position-absolute bottom-0 start-0 p-2" style={{ marginLeft: '-200px' }}>
        <div className="bg-white p-1 top-0 start-200 text-start" style={{fontSize: '20px'}}>
          <FiCloudLightning style={{fontSize: '40px', margin: '10px', color: 'red'}}/>Speed
        </div>
        <div className="bg-white p-1" style={{marginBottom: '30px', width: '300px', fontSize: '15px'}}>
          Accelerate application and network performance while rapidly developing new applications
        </div>
      </div>
      <div className="position-absolute bottom-0 end-0 p-2" style={{ marginRight: '-200px' }}>
        <div className="bg-white p-1 top-0 start-200 text-start" style={{fontSize: '20px'}}>
          <FaArrowDownLong style={{fontSize: '40px', margin: '10px', color: 'red'}}/>Cost
        </div>
        <div className="bg-white p-1" style={{marginBottom: '30px', width: '300px', fontSize: '15px'}}>
          Reduce cost and complexity to reinvest resources in your highest priorities
        </div>
      </div>
      <div className='align-items-center mt5 mb5'>
        <Button variant='danger' style={{fontSize: '15px', height: '50px', width: '300px'}} onClick={goToTelegram}>Contact for sale</Button>
      </div>
    </Container>
  );
};

export default ImageWithText;
