import React, { useState, useEffect, useContext } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Nav } from 'react-bootstrap';
import ThemeContext from '../../../ThemeContext'; // Исправленный путь к импорту ThemeContext
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const fetchData = async () => {
    // Extract account and domain from the URL path
    const pathParts = window.location.pathname.split('/');
    const account = pathParts[1]; // Adjust the index based on your URL structure
    const domain = pathParts[2]; // Adjust the index based on your URL structure

    const url = `https://${domain}/vm/api/v1/metric?ResultCounter=2880`;

    try {
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const result = await response.json();
        console.log('Fetched data:', result.data.viewer.series);
        return result.data.viewer.series;
    } catch (error) {
        console.error('Error fetching data:', error);
        toast.error(`${domain} error`);
        return [];
    }
};

const aggregateData = (data) => {
    if (!data || data.length === 0) {
        console.error('No data to aggregate');
        return [];
    }

    const aggregatedData = [];
    const pointsPerHour = Math.floor(data.length / 24);

    for (let i = 0; i < 24; i++) {
        const hourData = data.slice(i * pointsPerHour, (i + 1) * pointsPerHour);
        const total = hourData.reduce((sum, point) => sum + point.metric.total, 0);
        const bypassing = hourData.reduce((sum, point) => sum + point.metric.bypassing, 0);
        aggregatedData.push({
            name: `${i}:00`,
            TotalRequests: total,
            UniqueVisitors: bypassing,
        });
    }

    console.log('Aggregated data:', aggregatedData);
    return aggregatedData;
};

const ChartComponent = () => {
    const [data, setData] = useState([]);
    const [dataTotal, setDataTotal] = useState([]);
    const [activeTab, setActiveTab] = useState('24 Hours');
    const { theme } = useContext(ThemeContext);

    useEffect(() => {
        const getData = async () => {
            const rawData = await fetchData();
            if (rawData.length > 0) {
                const aggregatedData = aggregateData(rawData);
                setData(aggregatedData);
                setDataTotal(aggregatedData);
            }
        };

        getData();
    }, []);

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    return (
        <div className={`chart-container ${theme}`} style={{ backgroundColor: theme === 'dark' ? '#333' : 'white', padding: '20px', color: theme === 'dark' ? 'white' : 'black', width: '100%', maxWidth: '800px', margin: '5 auto', textAlign: 'left' }}>
            <Nav variant="tabs" defaultActiveKey="24 Hours" className="mb-3">
                <Nav.Item>
                    <Nav.Link eventKey="24 Hours" onClick={() => handleTabClick('24 Hours')} className={theme}><span style={{ color: '#9067b6'}}>24 Hours</span></Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link disabled="true" eventKey="7 Days" onClick={() => handleTabClick('7 Days')} className={theme}><span style={{ color: '#9067b6'}}>7 Days</span></Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link disabled="true" eventKey="30 Days" onClick={() => handleTabClick('30 Days')} className={theme}><span style={{ color: '#9067b6'}}>30 Days</span></Nav.Link>
                </Nav.Item>
            </Nav>

            <h4 className={theme}>Unique Visitors</h4>
            <ResponsiveContainer width="100%" height={200}>
                <LineChart data={data}>
                    <CartesianGrid strokeDasharray="3 3" stroke={theme === 'dark' ? '#ccc' : '#444'} />
                    <XAxis dataKey="name" stroke={theme === 'dark' ? 'white' : 'black'} />
                    <YAxis stroke={theme === 'dark' ? 'white' : 'black'} />
                    <Tooltip contentStyle={{ backgroundColor: theme === 'dark' ? '#555' : '#e2e2e2', borderColor: theme === 'dark' ? '#aaa' : '#333', color: theme === 'dark' ? 'white' : 'black', fontSize: '13px', borderRadius: '15px' }} />
                    <Legend wrapperStyle={{ color: theme === 'dark' ? 'white' : 'black' }} />
                    <Line type="monotone" dataKey="UniqueVisitors" stroke="#9067b6" dot={{ fill: theme === 'dark' ? '#333' : '#fff' }} activeDot={{ r: 8 }} />
                </LineChart>
            </ResponsiveContainer>

            <h4 className={theme}>Total Requests</h4>
            <ResponsiveContainer width="100%" height={200}>
                <LineChart data={dataTotal}>
                    <CartesianGrid strokeDasharray="3 3" stroke={theme === 'dark' ? '#ccc' : '#444'} />
                    <XAxis dataKey="name" stroke={theme === 'dark' ? 'white' : 'black'} />
                    <YAxis stroke={theme === 'dark' ? 'white' : 'black'} />
                    <Tooltip contentStyle={{ backgroundColor: theme === 'dark' ? '#555' : '#e2e2e2', borderColor: theme === 'dark' ? '#aaa' : '#333', color: theme === 'dark' ? 'white' : 'black', fontSize: '13px', borderRadius: '15px' }} />
                    <Legend wrapperStyle={{ color: theme === 'dark' ? 'white' : 'black' }} />
                    <Line type="monotone" dataKey="TotalRequests" stroke="#9067b6" dot={{ fill: theme === 'dark' ? '#333' : '#fff' }} activeDot={{ r: 8 }} />
                </LineChart>
            </ResponsiveContainer>
            <ToastContainer />
        </div>
    );
};

export default ChartComponent;
