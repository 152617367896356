// src/SecurityCenter.js
import React, { useState, useContext } from 'react';
import { Container, Row, Col, InputGroup, FormControl, Button, Card } from 'react-bootstrap';
import './Dashboard.css'; 
import './Menu.js';
import './Login.css';
import './SecurityCenter.css';
import { useNavigate } from 'react-router-dom';
import { BsGeoAlt, BsFillGeoFill } from "react-icons/bs";
import { AiFillSecurityScan, AiOutlineMail } from "react-icons/ai";
import { FaSearch } from 'react-icons/fa';
import { TbCircuitChangeover } from "react-icons/tb";
import { SiJetpackcompose } from "react-icons/si";
import { GoOrganization } from "react-icons/go";
import { MdLocationCity, MdOutlineDns } from "react-icons/md";
import { FaRegIdBadge, FaRegWindowRestore, FaRegHourglass } from "react-icons/fa";
import { RiMapPinTimeLine } from "react-icons/ri";
import MenuNavigation from './Menu.js';
import { useTranslation } from 'react-i18next';
import ThemeContext from './ThemeContext';

const SecurityCenter = () => {
    const { t } = useTranslation();
    const username = localStorage.getItem('username');
    const navigate = useNavigate();
    const { theme } = useContext(ThemeContext);

    const [inputValue, setInputValue] = useState('');
    const [ipResult, setIpResult] = useState(null);
    const [whoisResult, setWhoisResult] = useState(null);
    const [error, setError] = useState('');

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const determineType = (input) => {
        const ipPattern = /^(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}$/;
        const urlPattern = /^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

        if (ipPattern.test(input)) {
            return 'IP';
        } else if (urlPattern.test(input)) {
            return 'Domain';
        } else {
            return 'Invalid';
        }
    };

    const formatDate = (dateStr) => {
        if (!dateStr) return 'N/A';
        const date = new Date(dateStr);
        return date.toLocaleDateString();
    };

    const fetchIPInfo = async (ip) => {
        const ipApiKey = '64c692d9facf4a54a4899655730220d8';
        const response = await fetch(`https://api.ipgeolocation.io/ipgeo?apiKey=${ipApiKey}&ip=${ip}`);
        const data = await response.json();
        return data;
    };

    const fetchWhoisInfo = async (query) => {
        try {
            const response = await fetch(`https://rdap.org/domain/${query}`);
            if (!response.ok) throw new Error('Failed to fetch WHOIS data');
            const data = await response.json();
            return data;
        } catch (error) {
            return null; // Возвращаем null в случае ошибки
        }
    };

    const handleSearch = async () => {
        setIpResult(null);
        setWhoisResult(null);
        setError('');

        const type = determineType(inputValue);

        if (type === 'IP' || type === 'Domain') {
            try {
                let ipData, whoisData, ip;

                if (type === 'IP') {
                    ip = inputValue;
                    ipData = await fetchIPInfo(ip);
                    whoisData = await fetchWhoisInfo(ip);
                    console.log('IP Data:', ipData);
                    console.log('WHOIS Data:', whoisData);
                } else if (type === 'Domain') {
                    const domainResponse = await fetch(`https://api.ipgeolocation.io/getip?apiKey=64c692d9facf4a54a4899655730220d8&domain=${inputValue}`);
                    const domainData = await domainResponse.json();
                    ip = domainData.ip;

                    ipData = await fetchIPInfo(ip);
                    whoisData = await fetchWhoisInfo(inputValue); // Здесь используем домен, а не IP
                    console.log('Domain IP:', ip);
                    console.log('IP Data:', ipData);
                    console.log('WHOIS Data:', whoisData);
                }

                setIpResult(ipData);
                setWhoisResult(whoisData || {}); // Устанавливаем пустой объект, если данные WHOIS не были получены
            } catch (error) {
                setError(t('Failed to fetch data. Please try again.'));
            }
        } else {
            setError(t('Please enter a valid IP address or domain.'));
        }
    };

    const cardStyle = {
        backgroundColor: theme === 'dark' ? '#333' : 'white',
        color: theme === 'dark' ? 'white' : 'black',
        border: '1px solid #444',
        padding: '10px',
        width: '100%',
        height: 'auto',
    };

    return (
        <Container fluid className={`dashboard-container ${theme}`}>
            <Row className="flex-grow-1">
                <Col md={2} className={`border vh-100 ${theme}`}>
                    <MenuNavigation />
                </Col>
                <Col md={10} className={`border vh-100 ${theme}`}>
                    <Container className='left-align-container'>
                        <h2 className={theme} style={{ display: 'flex', alignItems: 'center' }}>
                            <AiFillSecurityScan style={{ marginRight: '10px', fontSize: '22px', marginBottom: '5px', color: 'red' }} />
                            {t('Security Center')} <span className={theme} style={{ fontSize: '18px', color: 'gray', marginLeft: '10px' }}>hotwall@{username}'s account</span>
                        </h2>
                        <h6 className={theme}>{t('Scan an IP or domain')}</h6>
                        <InputGroup className="mb-3">
                            <InputGroup.Text>
                                <FaSearch />
                            </InputGroup.Text>
                            <FormControl
                                placeholder={t('IP or domain')}
                                value={inputValue}
                                onChange={handleInputChange}
                                className='custom-input'
                            />
                            <Button variant="danger" onClick={handleSearch}>{t('Secure')}</Button>
                        </InputGroup>
                        {error && <p style={{ color: 'red' }}>{error}</p>}
                        <Row>
                            {ipResult && (
                                <Col md={6}>
                                    <Card className={`db-info-card ${theme}`} style={cardStyle}>
                                        <Card.Body>
                                            <Card.Title style={{ textAlign: 'center' }}><MdOutlineDns style={{ marginRight: '10px', fontSize: '25px', color: 'red', marginBottom: '5px' }} />{t('IP Information')}</Card.Title>
                                            <Card.Text style={{ fontSize: '13px', textAlign: 'left' }}>
                                                <strong><BsGeoAlt style={{ marginRight: '10px', fontSize: '18px', color: 'red', marginBottom: '5px' }} />{t('IP Address')}:</strong> {ipResult.ip || 'N/A'}<br />
                                                <strong><BsFillGeoFill style={{ marginRight: '10px', fontSize: '18px', color: 'red', marginBottom: '5px' }} />{t('Resource Name')}:</strong> {ipResult.hostname || 'N/A'}<br />
                                                <strong><TbCircuitChangeover style={{ marginRight: '10px', fontSize: '18px', color: 'red', marginBottom: '5px' }} />{t('IP Range')}:</strong> {ipResult.ipv4 ? ipResult.ipv4 : 'N/A'}<br />
                                                <strong><SiJetpackcompose style={{ marginRight: '10px', fontSize: '18px', color: 'red', marginBottom: '5px' }} />{t('Provider')}:</strong> {ipResult.isp || 'N/A'}<br />
                                                <strong><GoOrganization style={{ marginRight: '10px', fontSize: '18px', color: 'red', marginBottom: '5px' }} />{t('Organization')}:</strong> {ipResult.organization || 'N/A'}<br />
                                                <strong><MdLocationCity style={{ marginRight: '10px', fontSize: '18px', color: 'red', marginBottom: '5px' }} />{t('Country')}:</strong> {ipResult.country_name || 'N/A'} ({ipResult.country_code2 || 'N/A'})<br />
                                                <strong><FaRegIdBadge style={{ marginRight: '10px', fontSize: '18px', color: 'red', marginBottom: '5px' }} />{t('Region')}:</strong> {ipResult.state_prov || 'N/A'}<br />
                                                <strong><FaRegWindowRestore style={{ marginRight: '10px', fontSize: '18px', color: 'red', marginBottom: '5px' }} />{t('City')}:</strong> {ipResult.city || 'N/A'}<br />
                                                <strong><FaRegHourglass style={{ marginRight: '10px', fontSize: '18px', color: 'red', marginBottom: '5px' }} />{t('Timezone')}:</strong> {ipResult.time_zone?.name || 'N/A'}<br />
                                                <strong><RiMapPinTimeLine style={{ marginRight: '10px', fontSize: '18px', color: 'red', marginBottom: '5px' }} />{t('Local Time')}:</strong> {ipResult.time_zone?.current_time || 'N/A'}<br />
                                                <strong><AiOutlineMail style={{ marginRight: '10px', fontSize: '18px', color: 'red', marginBottom: '5px' }} />{t('Postal Code')}:</strong> {ipResult.zipcode || 'N/A'}
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            )}
                            {whoisResult && (
                                <Col md={6}>
                                    <Card className={`db-info-card ${theme}`} style={cardStyle}>
                                        <Card.Body>
                                            <Card.Title style={{ textAlign: 'center' }}><MdOutlineDns style={{ marginRight: '10px', fontSize: '25px', color: 'red', marginBottom: '5px' }} />{t('WHOIS Information')}</Card.Title>
                                            <Card.Text style={{ fontSize: '13px', textAlign: 'left' }}>
                                                <strong><FaRegIdBadge style={{ marginRight: '10px', fontSize: '18px', color: 'red', marginBottom: '5px' }} />{t('Domain Name')}:</strong> {whoisResult.ldhName || 'N/A'}<br />
                                                <strong><FaRegIdBadge style={{ marginRight: '10px', fontSize: '18px', color: 'red', marginBottom: '5px' }} />{t('Registrar')}:</strong> {whoisResult.entities?.[0].vcardArray?.[1]?.[1]?.[3] || 'N/A'}<br />
                                                <strong><FaRegIdBadge style={{ marginRight: '10px', fontSize: '18px', color: 'red', marginBottom: '5px' }} />{t('Registration Date')}:</strong> {formatDate(whoisResult.events?.find(event => event.eventAction === 'registration')?.eventDate) || 'N/A'}<br />
                                                <strong><FaRegIdBadge style={{ marginRight: '10px', fontSize: '18px', color: 'red', marginBottom: '5px' }} />{t('Expiration Date')}:</strong> {formatDate(whoisResult.events?.find(event => event.eventAction === 'expiration')?.eventDate) || 'N/A'}<br />
                                                <strong><FaRegIdBadge style={{ marginRight: '10px', fontSize: '18px', color: 'red', marginBottom: '5px' }} />{t('Updated Date')}:</strong> {formatDate(whoisResult.events?.find(event => event.eventAction === 'last changed')?.eventDate) || 'N/A'}<br />
                                                <strong><FaRegIdBadge style={{ marginRight: '10px', fontSize: '18px', color: 'red', marginBottom: '5px' }} />{t('Status')}:</strong> {whoisResult.status?.join(', ') || 'N/A'}<br />
                                                <strong><FaRegIdBadge style={{ marginRight: '10px', fontSize: '18px', color: 'red', marginBottom: '5px' }} />{t('Name Servers')}:</strong> {whoisResult.nameservers?.map(ns => ns.ldhName).join(', ') || 'N/A'}
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            )}
                        </Row>
                    </Container>
                </Col>
            </Row>
        </Container>
    );
};

export default SecurityCenter;
